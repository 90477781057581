
import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useState, useRef } from "react";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { v4 as uuidv4 } from 'uuid';
import CardDisplay from "../components/components-ui/cardDisplay";
import StyledButton from './styled-components/Styled-Button';

const FlexFilterItems = styled.div`
    display:flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

function Post() {
    const data = useStaticQuery(graphql`
    query PostsDeImages {
      allMdx(limit: 10, sort: {fields: frontmatter___title, order: ASC}) {
        edges {
          node {
            id
            slug
            frontmatter {
              date
              summary
              title
              completed
              topic
              featured {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
            }
            timeToRead
            wordCount {
              words
            }
          }
        }
      }
    }
    
    
  `)

  const [filter, filterPosts] = useState("All")
  const buttonInput = useRef();
  const buttonNames = ["All", "Completed", "Software", "Visual-Novel", "Novel", "Games", "Mods", "Art", "Date-Recent", "Word-Count"]; 
  // Create a sub pack of strings and buttons for Topic
  // Create a three things. Acending Order, Decending Order, Completed 
  function FilterContent (e){
    e.preventDefault()
    console.log(e.currentTarget.value)
    filterPosts(e.currentTarget.value)
  }

  function ReturnSortFilterEdges(Edges){
    switch (filter) {
      case buttonNames[0]: // All
        return Edges
      
      case buttonNames[1]: // Completed
        return Edges.filter( edge => edge.node.frontmatter.completed === true)

      case buttonNames[2]: case buttonNames[3]: case buttonNames[4]: case buttonNames[5]: case buttonNames[6]: case buttonNames[7]:// Topic - This could be better.
        return Edges.filter( edge => edge.node.frontmatter.topic === `${filter}` )

      case buttonNames[8]: // Date-Recent
        return Edges.sort(function(d1, d2) {
          return new Date(d1.node.frontmatter.date) - new Date(d2.node.frontmatter.date);
        })

      case buttonNames[9]: // Word-Count
      return Edges.sort(function(d1, d2) {
        return d1.node.wordCount.words - d2.node.wordCount.words;
      })

      default:
        return Edges
    }
  }
  // We will use the the data query above to compare each component inside CardDisplay below
  //and add display: none; to each component that doesn't meet the comparison.

    return (
      <div>
        <FlexFilterItems>
            {buttonNames.map(buttonName =>{
              return(
                <div key = {uuidv4()}>
                  <StyledButton value={buttonName} onClick = {e => FilterContent(e, "value")}>
                    <a>{buttonName}</a>
                  </StyledButton>
                </div>
              )
            })}
        </FlexFilterItems>
        <CardDisplay>
          {ReturnSortFilterEdges(data.allMdx.edges).map(shadowNode => (
            <div key = {shadowNode.node.id}>
              <GatsbyImage
                image={shadowNode.node.frontmatter.featured.childImageSharp.gatsbyImageData}
                alt="A dinosaur"
                placeholder="blurred"
                layout = "fullWidth"
              />
              <div>
                  <h2>{shadowNode.node.frontmatter.title}</h2>
                  <p>{shadowNode.node.frontmatter.summary} <br/><br/> <strong>StartDate: </strong> {shadowNode.node.frontmatter.date}
                  <br/> <strong>Completed?</strong> {shadowNode.node.frontmatter.completed.toString().toLowerCase()}</p>
                  <br/> <p><strong>Project Type</strong> {shadowNode.node.frontmatter.topic}</p>
                  <li><Link to={shadowNode.node.slug}>Read More</Link></li>
              </div>
              
            </div>
          ))}
        </CardDisplay>
      </div>
    )
}


export default Post

// export const query = graphql`
//     query PostsBySlug($slug: String!){
//         mdx(slug: {eq: $slug}){
//             body
//             frontmatter{
//                 title
//                 date
//             }
//         }
//     }
// `
