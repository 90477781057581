import * as React from "react"
import { Link} from "gatsby"
import Seo from "../components/seo"
import Divider from "../components/components-ui/Divider"
import GlobalThemeStyles from '../components/components-ui/GlobalThemeStyles'
import Post from "../components/Post"
import styled from "styled-components"
import MainPageImage from "../components/miscellaneous/MainPageImage"

const Section = styled.section`
  display: grid;
  grid-template-columns: [MainContent]1fr [MainImage] 1fr ;
  gap: 1rem;
  margin-top: 3em;
  margin-bottom: 3em;
  > div{
    grid-row: 1 / 3;
  }
`;
function IndexPage({data}) {
  //Create a loop that does a grahpQL refrence and loops through the data to make  multiple cards.
  return (
    <>
      <GlobalThemeStyles/>
        <Seo title="Home"/>
        <Section>
          <div style = {{gridColumn: "MainContent"}}>
            <h1 style = {{color:  `${props => props.theme.colors.text.textColourPrimary}`}}>The Archive! </h1>
            <p>Welcome to my site, that's 100% unfinshed. This website is only in a workable state. Since I'm focused on something else right now.</p>
            <p>Now, await awhile until this is completed.</p>
          </div>
          <div style = {{gridColumn: "MainImage"}}>
            <MainPageImage/>
          </div>
        </Section>
        <Divider></Divider>
        <Post></Post>
        <Divider></Divider>
        <p>
          <Link to="/Cv/">CV</Link> <br />
          <Link to="/Services/">Services</Link> <br />
          <Link to="/About/">About</Link> <br />
        </p>
    </>
  )
}

export default IndexPage