import styled from "styled-components"

export default styled.button`
    background-color: ${props => props.theme.colors.TertiaryColor};
    padding: 0.5rem;
    border-radius: 2px;
    display: block;
    border-style: none;
    cursor: pointer;
    transition: background-color 0.25s;
    :hover{
        background-color: ${props => props.theme.colors.ForthColor};
    }
`