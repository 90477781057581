import React from 'react'
import {useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const MainPageImage = () => {
    const firstPageFrontImage = useStaticQuery(graphql`
    query QueryMainPageImage{
      placeholderImage: file(relativePath: {eq: "Trigun-Vash-Love-and-Peace.png"}) {
        id
        childImageSharp {
          gatsbyImageData(width: 500)
        }
      }
    }
  `)
    return (
        <>
            <GatsbyImage 
                image={firstPageFrontImage.placeholderImage.childImageSharp.gatsbyImageData}
                alt="A dinosaur"
                placeholder="blurred"
                layout = "fullWidth"
            />
        </>
    )
}

export default MainPageImage
